var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "list-view product-checkout mt-0"
  }, [_c('e-commerce-checkout-step-cart-products'), _c('div', {
    staticClass: "checkout-options"
  }, [_c('b-card', [_c('label', {
    staticClass: "section-label mb-1"
  }, [_vm._v("Options")]), _c('b-input-group', {
    staticClass: "input-group-merge coupons"
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Coupons"
    }
  }), _c('b-input-group-append', {
    attrs: {
      "is-text": ""
    }
  }, [_c('span', {
    staticClass: "input-group-text text-primary cursor-pointer",
    attrs: {
      "id": "input-coupons"
    }
  }, [_vm._v("Apply")])])], 1), _c('hr'), _c('div', {
    staticClass: "price-details"
  }, [_c('h6', {
    staticClass: "price-title"
  }, [_vm._v(" Price Details ")]), _c('ul', {
    staticClass: "list-unstyled"
  }, [_c('li', {
    staticClass: "price-detail"
  }, [_c('div', {
    staticClass: "detail-title"
  }, [_vm._v(" Total MRP ")]), _c('div', {
    staticClass: "detail-amt"
  }, [_vm._v(" $598 ")])]), _c('li', {
    staticClass: "price-detail"
  }, [_c('div', {
    staticClass: "detail-title"
  }, [_vm._v(" Bag Discount ")]), _c('div', {
    staticClass: "detail-amt discount-amt text-success"
  }, [_vm._v(" -25$ ")])]), _c('li', {
    staticClass: "price-detail"
  }, [_c('div', {
    staticClass: "detail-title"
  }, [_vm._v(" Estimated Tax ")]), _c('div', {
    staticClass: "detail-amt"
  }, [_vm._v(" $1.3 ")])]), _c('li', {
    staticClass: "price-detail"
  }, [_c('div', {
    staticClass: "detail-title"
  }, [_vm._v(" EMI Eligibility ")]), _c('a', {
    staticClass: "detail-amt text-primary",
    attrs: {
      "href": "javascript:void(0)"
    }
  }, [_vm._v("Details")])]), _c('li', {
    staticClass: "price-detail"
  }, [_c('div', {
    staticClass: "detail-title"
  }, [_vm._v(" Delivery Charges ")]), _c('div', {
    staticClass: "detail-amt discount-amt text-success"
  }, [_vm._v(" Free ")])])]), _c('hr'), _c('ul', {
    staticClass: "list-unstyled"
  }, [_c('li', {
    staticClass: "price-detail"
  }, [_c('div', {
    staticClass: "detail-title detail-total"
  }, [_vm._v(" Total ")]), _c('div', {
    staticClass: "detail-amt font-weight-bolder"
  }, [_vm._v(" $574 ")])])]), _c('b-button', {
    attrs: {
      "variant": "primary",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('next-step');
      }
    }
  }, [_vm._v(" Place Order ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }