var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form-wizard', {
    ref: "refFormWizard",
    staticClass: "checkout-form-wizard steps-transparent",
    attrs: {
      "color": "#7367F0",
      "title": null,
      "subtitle": null,
      "finish-button-text": "Submit",
      "back-button-text": "Previous",
      "hide-buttons": ""
    }
  }, [_c('tab-content', {
    attrs: {
      "title": "Cart",
      "icon": "feather icon-shopping-cart"
    }
  }, [_c('e-commerce-checkout-step-cart', {
    on: {
      "next-step": _vm.formWizardNextStep
    }
  })], 1), _c('tab-content', {
    attrs: {
      "title": "Address",
      "icon": "feather icon-home"
    }
  }, [_c('e-commerce-checkout-step-address', {
    attrs: {
      "address-details": _vm.checkoutDetails.address
    },
    on: {
      "next-step": _vm.formWizardNextStep
    }
  })], 1), _c('tab-content', {
    attrs: {
      "title": "Payment",
      "icon": "feather icon-credit-card"
    }
  }, [_c('e-commerce-checkout-step-payment', {
    attrs: {
      "payment-details": _vm.checkoutDetails.payment
    },
    on: {
      "next-step": _vm.formWizardNextStep
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }