var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('b-form', {
          staticClass: "list-view product-checkout",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.onSubmit);
            }
          }
        }, [_c('b-card', {
          attrs: {
            "no-body": ""
          }
        }, [_c('b-card-header', {
          staticClass: "flex-column align-items-start"
        }, [_c('b-card-title', [_vm._v("Add New Address")]), _c('b-card-text', {
          staticClass: "text-muted mt-25"
        }, [_vm._v(" Be sure to check \"Deliver to this address\" when you have finished ")])], 1), _c('b-card-body', [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Full Name",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                staticClass: "mb-2",
                attrs: {
                  "label": "Full Name",
                  "label-for": "full-name"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "id": "full-name",
                  "state": _vm.getValidationState(validationContext),
                  "trim": ""
                },
                model: {
                  value: _vm.addressDetails.fullName,
                  callback: function callback($$v) {
                    _vm.$set(_vm.addressDetails, "fullName", $$v);
                  },
                  expression: "addressDetails.fullName"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          staticClass: "mb-2",
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Mobile Number",
            "rules": "required|integer"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label": "Mobile Number",
                  "label-for": "mobile-number"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "id": "mobile-number",
                  "type": "number",
                  "state": _vm.getValidationState(validationContext),
                  "trim": ""
                },
                model: {
                  value: _vm.addressDetails.mobile,
                  callback: function callback($$v) {
                    _vm.$set(_vm.addressDetails, "mobile", $$v);
                  },
                  expression: "addressDetails.mobile"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Flat, House No",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                staticClass: "mb-2",
                attrs: {
                  "label": "Flat, House No",
                  "label-for": "flat-house-no"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "id": "flat-house-no",
                  "state": _vm.getValidationState(validationContext),
                  "trim": ""
                },
                model: {
                  value: _vm.addressDetails.houseNo,
                  callback: function callback($$v) {
                    _vm.$set(_vm.addressDetails, "houseNo", $$v);
                  },
                  expression: "addressDetails.houseNo"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Landmark",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                staticClass: "mb-2",
                attrs: {
                  "label": "Landmark",
                  "label-for": "landmark"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "id": "landmark",
                  "state": _vm.getValidationState(validationContext),
                  "trim": ""
                },
                model: {
                  value: _vm.addressDetails.landmark,
                  callback: function callback($$v) {
                    _vm.$set(_vm.addressDetails, "landmark", $$v);
                  },
                  expression: "addressDetails.landmark"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Town/city",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                staticClass: "mb-2",
                attrs: {
                  "label": "Town/City",
                  "label-for": "city"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "id": "city",
                  "state": _vm.getValidationState(validationContext),
                  "trim": ""
                },
                model: {
                  value: _vm.addressDetails.city,
                  callback: function callback($$v) {
                    _vm.$set(_vm.addressDetails, "city", $$v);
                  },
                  expression: "addressDetails.city"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Pincode",
            "rules": "required|integer"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                staticClass: "mb-2",
                attrs: {
                  "label": "Pincode",
                  "label-for": "pincode"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "id": "pincode",
                  "type": "number",
                  "state": _vm.getValidationState(validationContext),
                  "trim": ""
                },
                model: {
                  value: _vm.addressDetails.pincode,
                  callback: function callback($$v) {
                    _vm.$set(_vm.addressDetails, "pincode", $$v);
                  },
                  expression: "addressDetails.pincode"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "State",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                staticClass: "mb-2",
                attrs: {
                  "label": "State",
                  "label-for": "state"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "id": "state",
                  "state": _vm.getValidationState(validationContext),
                  "trim": ""
                },
                model: {
                  value: _vm.addressDetails.state,
                  callback: function callback($$v) {
                    _vm.$set(_vm.addressDetails, "state", $$v);
                  },
                  expression: "addressDetails.state"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Address Type",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label": "Address Type",
                  "label-for": "address-type",
                  "state": _vm.getValidationState(validationContext)
                }
              }, [_c('v-select', {
                attrs: {
                  "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
                  "options": ['Home', 'Work'],
                  "input-id": "address-type",
                  "clearable": false
                },
                model: {
                  value: _vm.addressDetails.addressType,
                  callback: function callback($$v) {
                    _vm.$set(_vm.addressDetails, "addressType", $$v);
                  },
                  expression: "addressDetails.addressType"
                }
              }), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext)
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "primary",
            "type": "submit"
          }
        }, [_vm._v(" Save and Deliver Here ")])], 1)], 1)], 1)], 1), _c('div', {
          staticClass: "customer-card"
        }, [_c('b-card', {
          attrs: {
            "title": "John Doe"
          }
        }, [_c('b-card-text', {
          staticClass: "mb-0"
        }, [_vm._v(" 9447 Glen Eagles Drive ")]), _c('b-card-text', [_vm._v("Lewis Center, OH 43035")]), _c('b-card-text', [_vm._v("UTC-5: Eastern Standard Time (EST)")]), _c('b-card-text', [_vm._v("202-555-0140")]), _c('b-button', {
          attrs: {
            "variant": "primary",
            "block": ""
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('next-step');
            }
          }
        }, [_vm._v(" Deliver To This Address ")])], 1)], 1)], 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }