var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "list-view product-checkout"
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    staticClass: "flex-column align-items-start"
  }, [_c('b-card-title', [_vm._v("Payment Options")]), _c('b-card-text', {
    staticClass: "text-muted mt-25"
  }, [_vm._v(" Be sure to click on correct payment option ")])], 1), _c('b-card-body', [_c('h6', {
    staticClass: "card-holder-name my-75"
  }, [_vm._v(" John Doe ")]), _c('b-form-group', [_c('b-form-radio', {
    attrs: {
      "name": "payment-method",
      "value": "john-doe-debit-card",
      "checked": "john-doe-debit-card"
    }
  }, [_vm._v(" US Unlocked Debit Card 12XX XXXX XXXX 0000 ")]), _c('div', {
    staticClass: "d-flex flex-wrap align-items-center justify-content-start my-1"
  }, [_c('label', {
    staticClass: "text-nowrap mr-1 mb-1",
    attrs: {
      "for": "cvv"
    }
  }, [_vm._v(" Enter CVV: ")]), _c('b-form-input', {
    staticClass: "mr-1 mb-1",
    attrs: {
      "id": "cvv",
      "trim": ""
    },
    model: {
      value: _vm.paymentDetails.cvv,
      callback: function callback($$v) {
        _vm.$set(_vm.paymentDetails, "cvv", $$v);
      },
      expression: "paymentDetails.cvv"
    }
  }), _c('b-button', {
    staticClass: "mb-1",
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" Continue ")])], 1), _c('hr', {
    staticClass: "mb-2 mt-1"
  }), _c('b-form-radio', {
    attrs: {
      "name": "payment-method",
      "value": "debit-atm-credit-card"
    }
  }, [_vm._v(" Credit / Debit / ATM Card ")]), _c('b-form-radio', {
    staticClass: "mt-1",
    attrs: {
      "name": "payment-method",
      "value": "net-banking"
    }
  }, [_vm._v(" Net Banking ")]), _c('b-form-radio', {
    staticClass: "mt-1",
    attrs: {
      "name": "payment-method",
      "value": "emi"
    }
  }, [_vm._v(" EMI (Easy Installment) ")]), _c('b-form-radio', {
    staticClass: "mt-1",
    attrs: {
      "name": "payment-method",
      "value": "cod"
    }
  }, [_vm._v(" Cash On Delivery ")])], 1), _c('hr', {
    staticClass: "my-2"
  }), _c('p', {
    staticClass: "cursor-pointer"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "PlusCircleIcon",
      "size": "21"
    }
  }), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Add Gift Card")])], 1)], 1)], 1), _c('div', {
    staticClass: "amount-payable checkout-options"
  }, [_c('b-card', {
    attrs: {
      "title": "Price Details"
    }
  }, [_c('ul', {
    staticClass: "list-unstyled price-details"
  }, [_c('li', {
    staticClass: "price-detail"
  }, [_c('div', {
    staticClass: "details-title"
  }, [_vm._v(" Price of 3 items ")]), _c('div', {
    staticClass: "detail-amt"
  }, [_c('strong', [_vm._v("$699.30")])])]), _c('li', {
    staticClass: "price-detail"
  }, [_c('div', {
    staticClass: "details-title"
  }, [_vm._v(" Delivery Charges ")]), _c('div', {
    staticClass: "detail-amt discount-amt text-success"
  }, [_vm._v(" Free ")])])]), _c('hr'), _c('ul', {
    staticClass: "list-unstyled price-details"
  }, [_c('li', {
    staticClass: "price-detail"
  }, [_c('div', {
    staticClass: "details-title"
  }, [_vm._v(" Amount Payable ")]), _c('div', {
    staticClass: "detail-amt font-weight-bolder"
  }, [_vm._v(" $699.30 ")])])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }